import React from 'react';
import './style.scss'
import logo from '../../assets/images/logo.svg';

const Index = () => {

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }

    return (
        <div className='footer__main'>
            <div className='custom__container footer__sub__section'>
                <div>
                    <img src={logo} alt='' />
                </div>
                <div>
                    <button onClick={scrollToTop}>
                        Back to Top
                    </button>
                </div>
                <div>
                    <p>© Copyright 2024 • Relibit Labs, LLC  • Privacy Policy </p>
                </div>
            </div>
        </div>
    )
}

export default Index