import React from 'react';
import profileImg from '../../../assets/images/profileImg.png'
import './style.scss';


const Index = () => {
    return (
        <div className='profile__section__main custom__container'>
            <h3>
                Who’s Behind League Ahead?
            </h3>
            <div className='d-flex justify-content-center profile__img__section'>
                <img src={profileImg} alt='' />
            </div>
            <p>
                Those closest to me often joke that I'm part-time human and part-time AI. It's a nod to my deep passion for technology. I'm the founder of this platform where we break down AI and show you how to use it to supercharge your business. I’m also involved in several other tech ventures, which you can check out here.
            </p>
            <p>
                This site is your go-to resource for practical AI insights. We simplify complex concepts, connect you with talented AI teams, and most importantly, help you put AI strategies into action so you can stay ahead of the curve.
            </p>
            <p>
                I can't wait to have you onboard.
            </p>
            <div className='d-flex justify-content-center profile__btn__section'>
                <button>
                    More about me
                </button>
            </div>
        </div>
    )
}

export default Index