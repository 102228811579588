import React from 'react'
import './style.scss'
import { PrimaryBtn } from '../../../components';
import heroImg from '../../../assets/images/heroImg.png'

const Index = () => {
    return (
        <div className='custom__container hero__section__main'>
            <div className='hero__sub__div hero__text__section'>
                <h2>
                    Aren’t you tired of reading about AI and not getting it?
                </h2>
                <p>
                    We created the perfect course for non-engineers who want to grasp AI without feeling overwhelmed.
                </p>
                <div>
                    <PrimaryBtn text={"Access for <span>$99</span> $50"} />
                </div>
                <p className='last__text'>
                    Most finish it in a weekend, spare two days and your future self will thank you
                </p>
            </div>
            <div className='hero__sub__div hero__img__section'>
                    <img src={heroImg} alt='heroImg' className='main__img' />
            </div>
        </div>
    )
}

export default Index
