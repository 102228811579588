import React from 'react'
import './style.scss'

const Index = ({ text, onClick }) => {
    return (
        <button dangerouslySetInnerHTML={{__html:text}} onClick={onClick} className='primary__btn'>
        </button>
    )
}

export default Index