import React from 'react'
import './style.scss'
import { PlayIcon } from '../../../assets/icons'
import { SectionHeading } from '../../../components'

const Index = ({ testimonialRef }) => {
  return (
    <div ref={testimonialRef} className='videos__section__main custom__container'>
      <SectionHeading text={"What people are saying about League Ahead"} />
      <p>
        213+ non-engineering professionals have found this course incredibly valuable. Here’s what they have to say
      </p>
      <div className='videos__section'>
        <div className='video'>
          <button className='play__btn'><PlayIcon /></button>
        </div>
        <div className='video'>
          <button className='play__btn'><PlayIcon /></button>
        </div>
        <div className='video'>
          <button className='play__btn'><PlayIcon /></button>
        </div>
      </div>
    </div>
  )
}

export default Index
