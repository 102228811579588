import React from 'react'
import './App.scss';
import AppRouter from './config/AppRouter';

const App = () => {
  return (
    <AppRouter />
  )
}

export default App
