export const PlayIcon = () => {
    return <svg xmlns="http://www.w3.org/2000/svg" width="43" height="42" viewBox="0 0 43 42" fill="none">
        <path d="M21.8535 41C32.8632 41 41.7884 32.0457 41.7884 21C41.7884 9.9543 32.8632 1 21.8535 1C10.8438 1 1.91861 9.9543 1.91861 21C1.91861 32.0457 10.8438 41 21.8535 41Z" stroke="#F2F7FC" stroke-width="2" stroke-miterlimit="10" />
        <path d="M28.4984 21L18.531 14.3334V27.6667L28.4984 21Z" stroke="#F2F7FC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
}

export const GiftIcon = () => {
    return <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="56" height="56" rx="28" fill="#3B3086" />
        <path d="M39 22H17C16.4477 22 16 22.4477 16 23V27C16 27.5523 16.4477 28 17 28H39C39.5523 28 40 27.5523 40 27V23C40 22.4477 39.5523 22 39 22Z" stroke="#F2F7FC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M38 28V37C38 37.2652 37.8946 37.5196 37.7071 37.7071C37.5196 37.8946 37.2652 38 37 38H19C18.7348 38 18.4804 37.8946 18.2929 37.7071C18.1054 37.5196 18 37.2652 18 37V28" stroke="#F2F7FC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M28 22V38" stroke="#F2F7FC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M33.6625 20.5875C32.2375 22 28 22 28 22C28 22 28 17.7625 29.4125 16.3375C29.9761 15.7739 30.7405 15.4573 31.5375 15.4573C32.3345 15.4573 33.0989 15.7739 33.6625 16.3375C34.2261 16.9011 34.5427 17.6654 34.5427 18.4625C34.5427 19.2595 34.2261 20.0239 33.6625 20.5875V20.5875Z" stroke="#F2F7FC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M22.3375 20.5875C23.7625 22 28 22 28 22C28 22 28 17.7625 26.5875 16.3375C26.0239 15.7739 25.2595 15.4573 24.4625 15.4573C23.6655 15.4573 22.9011 15.7739 22.3375 16.3375C21.7739 16.9011 21.4573 17.6654 21.4573 18.4625C21.4573 19.2595 21.7739 20.0239 22.3375 20.5875V20.5875Z" stroke="#F2F7FC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        <path opacity="0.2" d="M38 28V37C38 37.2652 37.8946 37.5196 37.7071 37.7071C37.5196 37.8946 37.2652 38 37 38H19C18.7348 38 18.4804 37.8946 18.2929 37.7071C18.1054 37.5196 18 37.2652 18 37V28H38Z" fill="#F2F7FC" />
    </svg>
}

export const CheckIcon = () => {
    return <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="32" height="32" rx="16" fill="#7291F9" />
        <path d="M22.875 11.625L14.125 20.375L9.75 16" stroke="#F2F7FC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    </svg>

}

export const StarIcon = () => {
    return <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="32" height="32" rx="16" fill="#7291F9" />
        <path opacity="0.2" d="M16.3437 20.8985L20.2812 23.3985C20.7891 23.7188 21.4141 23.2422 21.2656 22.6563L20.125 18.1719C20.0942 18.0476 20.0991 17.9171 20.1391 17.7955C20.1792 17.6738 20.2529 17.566 20.3516 17.4844L23.8828 14.5391C24.3437 14.1563 24.1094 13.3828 23.5078 13.3438L18.8984 13.0469C18.7727 13.0396 18.6518 12.9958 18.5505 12.9209C18.4492 12.8459 18.3719 12.7431 18.3281 12.625L16.6094 8.29689C16.5639 8.17182 16.481 8.06377 16.372 7.98742C16.263 7.91107 16.1331 7.87012 16 7.87012C15.8669 7.87012 15.737 7.91107 15.628 7.98742C15.519 8.06377 15.4361 8.17182 15.3906 8.29689L13.6719 12.625C13.6281 12.7431 13.5508 12.8459 13.4495 12.9209C13.3482 12.9958 13.2273 13.0396 13.1016 13.0469L8.49218 13.3438C7.89062 13.3828 7.65625 14.1563 8.11718 14.5391L11.6484 17.4844C11.7471 17.566 11.8208 17.6738 11.8609 17.7955C11.9009 17.9171 11.9058 18.0476 11.875 18.1719L10.8203 22.3281C10.6406 23.0313 11.3906 23.6016 11.9922 23.2188L15.6562 20.8985C15.759 20.8331 15.8782 20.7984 16 20.7984C16.1218 20.7984 16.241 20.8331 16.3437 20.8985Z" fill="#F2F7FC" />
        <path d="M16.3437 20.8985L20.2812 23.3985C20.7891 23.7188 21.4141 23.2422 21.2656 22.6563L20.125 18.1719C20.0942 18.0476 20.0991 17.9171 20.1391 17.7955C20.1792 17.6738 20.2529 17.566 20.3516 17.4844L23.8828 14.5391C24.3437 14.1563 24.1094 13.3828 23.5078 13.3438L18.8984 13.0469C18.7727 13.0396 18.6518 12.9958 18.5505 12.9209C18.4492 12.8459 18.3719 12.7431 18.3281 12.625L16.6094 8.29689C16.5639 8.17182 16.481 8.06377 16.372 7.98742C16.263 7.91107 16.1331 7.87012 16 7.87012C15.8669 7.87012 15.737 7.91107 15.628 7.98742C15.519 8.06377 15.4361 8.17182 15.3906 8.29689L13.6719 12.625C13.6281 12.7431 13.5508 12.8459 13.4495 12.9209C13.3482 12.9958 13.2273 13.0396 13.1016 13.0469L8.49218 13.3438C7.89062 13.3828 7.65625 14.1563 8.11718 14.5391L11.6484 17.4844C11.7471 17.566 11.8208 17.6738 11.8609 17.7955C11.9009 17.9171 11.9058 18.0476 11.875 18.1719L10.8203 22.3281C10.6406 23.0313 11.3906 23.6016 11.9922 23.2188L15.6562 20.8985C15.759 20.8331 15.8782 20.7984 16 20.7984C16.1218 20.7984 16.241 20.8331 16.3437 20.8985V20.8985Z" stroke="#F2F7FC" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
}

export const ListCheckIcon = () => {
    return <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_7154_1842)">
            <g clip-path="url(#clip1_7154_1842)">
                <path opacity="0.2" d="M8 14.5C11.3137 14.5 14 11.8137 14 8.5C14 5.18629 11.3137 2.5 8 2.5C4.68629 2.5 2 5.18629 2 8.5C2 11.8137 4.68629 14.5 8 14.5Z" fill="#F2F7FC" />
                <path d="M10.75 7L7.08125 10.5L5.25 8.75" stroke="#F2F7FC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M8 14.5C11.3137 14.5 14 11.8137 14 8.5C14 5.18629 11.3137 2.5 8 2.5C4.68629 2.5 2 5.18629 2 8.5C2 11.8137 4.68629 14.5 8 14.5Z" stroke="#F2F7FC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            </g>
        </g>
        <defs>
            <clipPath id="clip0_7154_1842">
                <rect width="16" height="16" fill="white" transform="translate(0 0.5)" />
            </clipPath>
            <clipPath id="clip1_7154_1842">
                <rect width="16" height="16" fill="white" transform="translate(0 0.5)" />
            </clipPath>
        </defs>
    </svg>

}

export const GoodStar = () => {
    return <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="32" height="32" rx="16" fill="#E66BBA" />
        <path opacity="0.2" d="M16.3437 20.8985L20.2812 23.3985C20.7891 23.7188 21.4141 23.2422 21.2656 22.6563L20.125 18.1719C20.0942 18.0476 20.0991 17.9171 20.1391 17.7955C20.1792 17.6738 20.2529 17.566 20.3516 17.4844L23.8828 14.5391C24.3437 14.1563 24.1094 13.3828 23.5078 13.3438L18.8984 13.0469C18.7727 13.0396 18.6518 12.9958 18.5505 12.9209C18.4492 12.8459 18.3719 12.7431 18.3281 12.625L16.6094 8.29689C16.5639 8.17182 16.481 8.06377 16.372 7.98742C16.263 7.91107 16.1331 7.87012 16 7.87012C15.8669 7.87012 15.737 7.91107 15.628 7.98742C15.519 8.06377 15.4361 8.17182 15.3906 8.29689L13.6719 12.625C13.6281 12.7431 13.5508 12.8459 13.4495 12.9209C13.3482 12.9958 13.2273 13.0396 13.1016 13.0469L8.49218 13.3438C7.89062 13.3828 7.65625 14.1563 8.11718 14.5391L11.6484 17.4844C11.7471 17.566 11.8208 17.6738 11.8609 17.7955C11.9009 17.9171 11.9058 18.0476 11.875 18.1719L10.8203 22.3281C10.6406 23.0313 11.3906 23.6016 11.9922 23.2188L15.6562 20.8985C15.759 20.8331 15.8782 20.7984 16 20.7984C16.1218 20.7984 16.241 20.8331 16.3437 20.8985Z" fill="#F2F7FC" />
        <path d="M16.3437 20.8985L20.2812 23.3985C20.7891 23.7188 21.4141 23.2422 21.2656 22.6563L20.125 18.1719C20.0942 18.0476 20.0991 17.9171 20.1391 17.7955C20.1792 17.6738 20.2529 17.566 20.3516 17.4844L23.8828 14.5391C24.3437 14.1563 24.1094 13.3828 23.5078 13.3438L18.8984 13.0469C18.7727 13.0396 18.6518 12.9958 18.5505 12.9209C18.4492 12.8459 18.3719 12.7431 18.3281 12.625L16.6094 8.29689C16.5639 8.17182 16.481 8.06377 16.372 7.98742C16.263 7.91107 16.1331 7.87012 16 7.87012C15.8669 7.87012 15.737 7.91107 15.628 7.98742C15.519 8.06377 15.4361 8.17182 15.3906 8.29689L13.6719 12.625C13.6281 12.7431 13.5508 12.8459 13.4495 12.9209C13.3482 12.9958 13.2273 13.0396 13.1016 13.0469L8.49218 13.3438C7.89062 13.3828 7.65625 14.1563 8.11718 14.5391L11.6484 17.4844C11.7471 17.566 11.8208 17.6738 11.8609 17.7955C11.9009 17.9171 11.9058 18.0476 11.875 18.1719L10.8203 22.3281C10.6406 23.0313 11.3906 23.6016 11.9922 23.2188L15.6562 20.8985C15.759 20.8331 15.8782 20.7984 16 20.7984C16.1218 20.7984 16.241 20.8331 16.3437 20.8985V20.8985Z" stroke="#F2F7FC" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
}

export const BadStars = () => {
    return <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="32" height="32" rx="16" fill="#FF5876" />
        <path opacity="0.2" d="M16.3437 20.8985L20.2812 23.3985C20.7891 23.7188 21.4141 23.2422 21.2656 22.6563L20.125 18.1719C20.0942 18.0476 20.0991 17.9171 20.1391 17.7955C20.1792 17.6738 20.2529 17.566 20.3516 17.4844L23.8828 14.5391C24.3437 14.1563 24.1094 13.3828 23.5078 13.3438L18.8984 13.0469C18.7727 13.0396 18.6518 12.9958 18.5505 12.9209C18.4492 12.8459 18.3719 12.7431 18.3281 12.625L16.6094 8.29689C16.5639 8.17182 16.481 8.06377 16.372 7.98742C16.263 7.91107 16.1331 7.87012 16 7.87012C15.8669 7.87012 15.737 7.91107 15.628 7.98742C15.519 8.06377 15.4361 8.17182 15.3906 8.29689L13.6719 12.625C13.6281 12.7431 13.5508 12.8459 13.4495 12.9209C13.3482 12.9958 13.2273 13.0396 13.1016 13.0469L8.49218 13.3438C7.89062 13.3828 7.65625 14.1563 8.11718 14.5391L11.6484 17.4844C11.7471 17.566 11.8208 17.6738 11.8609 17.7955C11.9009 17.9171 11.9058 18.0476 11.875 18.1719L10.8203 22.3281C10.6406 23.0313 11.3906 23.6016 11.9922 23.2188L15.6562 20.8985C15.759 20.8331 15.8782 20.7984 16 20.7984C16.1218 20.7984 16.241 20.8331 16.3437 20.8985Z" fill="#F2F7FC" />
        <path d="M16.3437 20.8985L20.2812 23.3985C20.7891 23.7188 21.4141 23.2422 21.2656 22.6563L20.125 18.1719C20.0942 18.0476 20.0991 17.9171 20.1391 17.7955C20.1792 17.6738 20.2529 17.566 20.3516 17.4844L23.8828 14.5391C24.3437 14.1563 24.1094 13.3828 23.5078 13.3438L18.8984 13.0469C18.7727 13.0396 18.6518 12.9958 18.5505 12.9209C18.4492 12.8459 18.3719 12.7431 18.3281 12.625L16.6094 8.29689C16.5639 8.17182 16.481 8.06377 16.372 7.98742C16.263 7.91107 16.1331 7.87012 16 7.87012C15.8669 7.87012 15.737 7.91107 15.628 7.98742C15.519 8.06377 15.4361 8.17182 15.3906 8.29689L13.6719 12.625C13.6281 12.7431 13.5508 12.8459 13.4495 12.9209C13.3482 12.9958 13.2273 13.0396 13.1016 13.0469L8.49218 13.3438C7.89062 13.3828 7.65625 14.1563 8.11718 14.5391L11.6484 17.4844C11.7471 17.566 11.8208 17.6738 11.8609 17.7955C11.9009 17.9171 11.9058 18.0476 11.875 18.1719L10.8203 22.3281C10.6406 23.0313 11.3906 23.6016 11.9922 23.2188L15.6562 20.8985C15.759 20.8331 15.8782 20.7984 16 20.7984C16.1218 20.7984 16.241 20.8331 16.3437 20.8985V20.8985Z" stroke="#F2F7FC" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
}

export const BadListCheckIcon = () => {
    return <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
        <g opacity="0.5">
            <path d="M2.86201 12.1951L7.05734 7.99973L2.86201 3.8044C2.74057 3.67866 2.67337 3.51026 2.67489 3.33546C2.67641 3.16066 2.74652 2.99345 2.87013 2.86985C2.99373 2.74624 3.16094 2.67613 3.33574 2.67461C3.51054 2.67309 3.67894 2.74029 3.80467 2.86173L8.00001 7.05706L12.1953 2.86173C12.2568 2.79806 12.3304 2.74727 12.4117 2.71233C12.4931 2.67739 12.5806 2.659 12.6691 2.65823C12.7576 2.65746 12.8454 2.67433 12.9273 2.70785C13.0092 2.74137 13.0837 2.79087 13.1463 2.85347C13.2089 2.91606 13.2584 2.99049 13.2919 3.07243C13.3254 3.15436 13.3423 3.24214 13.3415 3.33066C13.3407 3.41918 13.3223 3.50666 13.2874 3.588C13.2525 3.66933 13.2017 3.7429 13.138 3.8044L8.94267 7.99973L13.138 12.1951C13.2017 12.2566 13.2525 12.3301 13.2874 12.4115C13.3223 12.4928 13.3407 12.5803 13.3415 12.6688C13.3423 12.7573 13.3254 12.8451 13.2919 12.927C13.2584 13.009 13.2089 13.0834 13.1463 13.146C13.0837 13.2086 13.0092 13.2581 12.9273 13.2916C12.8454 13.3251 12.7576 13.342 12.6691 13.3412C12.5806 13.3405 12.4931 13.3221 12.4117 13.2871C12.3304 13.2522 12.2568 13.2014 12.1953 13.1377L8.00001 8.94239L3.80467 13.1377C3.67894 13.2592 3.51054 13.3264 3.33574 13.3248C3.16094 13.3233 2.99373 13.2532 2.87013 13.1296C2.74652 13.006 2.67641 12.8388 2.67489 12.664C2.67337 12.4892 2.74057 12.3208 2.86201 12.1951Z" fill="#F2F7FC" />
        </g>
    </svg>
}

export const GoodListCheckIcon = () => {
    return <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_7154_2480)">
            <g clip-path="url(#clip1_7154_2480)">
                <path opacity="0.2" d="M8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14Z" fill="#F2F7FC" />
                <path d="M10.75 6.5L7.08125 10L5.25 8.25" stroke="#F2F7FC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14Z" stroke="#F2F7FC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            </g>
        </g>
        <defs>
            <clipPath id="clip0_7154_2480">
                <rect width="16" height="16" fill="white" />
            </clipPath>
            <clipPath id="clip1_7154_2480">
                <rect width="16" height="16" fill="white" />
            </clipPath>
        </defs>
    </svg>
}

export const CloseIcon = ({color}) => {
    return <svg width="18" height="18" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M16 1.61143L14.3886 0L8 6.38857L1.61143 0L0 1.61143L6.38857 8L0 14.3886L1.61143 16L8 9.61143L14.3886 16L16 14.3886L9.61143 8L16 1.61143Z" fill={color ?? "#FFC2C0"} />
    </svg>
}

export const MenuIcon = ({size,color}) => {
    return <svg width={size ?? "32"} height={size ?? "32"} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6.66663 9.33331H25.3333" stroke={color ?? "white"} strokeWidth="2" strokeLinecap="round" />
        <path d="M6.66663 16H20" stroke={color ?? "white"} strokeWidth="2" strokeLinecap="round" />
        <path d="M6.66663 22.6667H14.6666" stroke={color ?? "white"} strokeWidth="2" strokeLinecap="round" />
    </svg>
}