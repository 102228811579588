import React from 'react';
import './style.scss';
import { PrimaryBtn, SectionHeading } from '../../../components';
import VideoImg from '../../../assets/images/Video.png'

const Index = () => {
    return (
        <div className='more__than__course__section custom__container'>
            <SectionHeading text={"More than just a course."} />
            <p>
                League Ahead includes more than just lessons.
                <br />I built the course so we can all discuss and try things out together and make sure you apply what you learn.
            </p>
            <div className='video__container'>
                <img src={VideoImg} alt='' />
            </div>
            <div className='w-100 d-flex justify-content-center'>
                <PrimaryBtn text={"Access for <span>$99</span> $50"} />
            </div>
        </div>
    )
}

export default Index