import React from 'react';
import logo from '../../assets/images/logo.svg'
import { PrimaryBtn } from '..';
import './style.scss';
import { CloseIcon, MenuIcon } from '../../assets/icons';

const Index = ({ isSmallScreen, whatInsideRef, pricingRef, testimonialRef, width }) => {

    const [open, setOpen] = React.useState(false)

    const linksList = [
        {
            name: "Testimonials",
            ref: testimonialRef
        },
        {
            name: "What’s inside?",
            ref: whatInsideRef
        },
        {
            name: "Pricing",
            ref: pricingRef
        },
        // {
        //     name: "Pricing",
        //     ref: pricingRef
        // },
    ]

    const toggleMenu = () => {
        setOpen(!open)
    }

    const clickOnLink = (ref) => {
        setOpen(false)
        ref.current.scrollIntoView({ behavior: 'smooth' })
    }

    return (
        <>
            <div className="navbar__container position-fixed d-flex w-100">
                <div className='custom__container navbar__main__container'>
                    {isSmallScreen ? <> <div className='navbar__main'>
                        <div>
                            <img src={logo} alt='logo' />
                        </div>
                        <div className='d-flex gap-2 align-items-center'>
                            {width > 525 ? <PrimaryBtn text={"Access for <span>$99</span> $50"} /> : ""}
                            <button onClick={toggleMenu} className='menu__btn'>
                                {open ? <CloseIcon /> : <MenuIcon color="#FFC2C0" size={40} />}
                            </button>
                        </div>
                        {width < 526 ? <div className='fixed__container'>

                            <PrimaryBtn text={"Access for <span>$99</span> $50"} />
                        </div> : ""}
                    </div>
                        {<div className={`mobile__menu__container ${open ? "active__menu" : ""}`}>
                            <div className='mob__sub__div'>
                                <div className='mobile__menu__section'>
                                    {linksList?.map((v, i) => {
                                        return <div key={i}>
                                            <button onClick={() => clickOnLink(v?.ref)} className='link_style'>
                                                {v?.name}
                                            </button>
                                        </div>
                                    })}
                                </div>
                                <div>

                                </div>
                            </div>
                        </div>}</> :
                        <div className='navbar__main'>
                            <div>
                                <img src={logo} alt='logo' />
                            </div>
                            <div className='list__section d-flex align-items-center'>
                                {linksList?.map((v, i) => {
                                    return <button onClick={() => clickOnLink(v?.ref)} key={i}>
                                        {v.name}
                                    </button>
                                })}
                            </div>
                            <div>
                                <PrimaryBtn text={"Access for <span>$99</span> $50"} />
                            </div>
                        </div>}
                </div>
            </div>
            <div className='navbar__main__empty' />
        </>
    )
}

export default Index
