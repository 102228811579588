import React from 'react';
import './style.scss'
import { SectionHeading } from '../../../components';
import { BadListCheckIcon, BadStars, GoodListCheckIcon, GoodStar } from '../../../assets/icons';

const Index = () => {

    const eligiblityCriterias = [
        {
            title: "You're a good fit for this course if:",
            points: [
                "You want to stay ahead of the curve by understanding cutting-edge AI technologies",
                "You see the value in learning how to collaborate with AI experts.",
                "You’re ready to invest time in mastering AI fundamentals for long-term benefits.",
                "You recognize that mastering AI requires patience and continuous effort.",
                "You’re open to rethinking traditional business approaches using AI.",
                "You’re eager to understand AI and apply it in your personal and professional life.",
            ],
            listIcon: <GoodListCheckIcon />,
            mainIcon: <GoodStar />
        },
        {
            title: "You're <span>not</span> a good fit for this course if:",
            points: [
                "You think buying a course alone will make you an AI expert.",
                "You’re looking for a deep dive into specific AI tools rather than a comprehensive understanding.",
                "You believe AI is just a passing trend and not worth investing time in.",
                "You expect the course to focus solely on coding and technical details.",
                "You expect to become a machine learning developer solely after taking just this course.",
                "You find it difficult to learn or be taught by experts who may be younger than you in age.",
            ],
            listIcon: <BadListCheckIcon />,
            mainIcon: <BadStars />
        }
    ]


    return (
        <div className='eligibile__section__main custom__container'>
            <SectionHeading text={"Who this course is for?"} />
            <p className='eligible__description'>
                Not everyone is going to be a right fit for The Creator MBA.
            </p>
            <div className='cards__section'>
                {eligiblityCriterias?.map((v, i) => {
                    return <div className='eligible__card__main'>
                        <div className='d-flex align-items-center gap-3'>
                            <div>
                                {v?.mainIcon}
                            </div>
                            <div>
                                <h4 className='eligible__card__title' dangerouslySetInnerHTML={{ __html: v?.title }} />

                            </div>
                        </div>
                        <div className='empty__line' />
                        <div className='eligible__point__section'>
                            {v?.points?.map((e, i) => {
                                return <div className='eligble__point d-flex gap-2 align-items-center' key={i}>
                                    <div>
                                        {v?.listIcon}
                                    </div>
                                    <div>
                                        <p>
                                            {e}
                                        </p>
                                    </div>
                                </div>
                            })}
                        </div>
                    </div>
                })}
            </div>
        </div>
    )
}

export default Index
