import React, { useEffect, useRef, useState } from 'react';
import './style.scss';
import { Footer, Navbar } from '../../components';
import { EligiblePeople, FeaturesSection, GuarantySection, HeroSection, MoreThanJustCourse, PricingSection, ProfileSection, VideosSection, WhatYouLearn } from '../../layouts';

const Index = () => {

  const [width, setWidth] = useState(window?.innerWidth)

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const testimonialRef = useRef(null);
  const pricingRef = useRef(null);
  const whatInsideRef = useRef(null);

  return (
    <>
      <div className='dark__blue__section'>
        <Navbar
          testimonialRef={testimonialRef}
          pricingRef={pricingRef}
          whatInsideRef={whatInsideRef}
          isSmallScreen={width < 991}
          width={width}
        />
        <HeroSection />
        <VideosSection testimonialRef={testimonialRef} />
        <GuarantySection isSmallScreen={width < 768} />
        <WhatYouLearn whatInsideRef={whatInsideRef} />
      </div>
      <div className='light__blue__section'>
        <FeaturesSection />
      </div>
      <div className='dark__blue__section'>
        <MoreThanJustCourse />
        <PricingSection pricingRef={pricingRef} />
        <ProfileSection />
        <EligiblePeople />
        <Footer />
      </div>
    </>
  )
}

export default Index