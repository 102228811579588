import React from 'react';
import './style.scss';
import image from '../../../assets/images/whatyoulearnImg.png'
import { CheckIcon } from '../../../assets/icons';
import { SectionHeading } from '../../../components';

const Index = ({ whatInsideRef }) => {

    const features = [
        {
            title: "The concepts everyone should know",
            description: "Understand key AI topics, break down complex ideas, and gain insights into AI mechanisms."
        },
        {
            title: "The real-world applications of AI",
            description: "Apply AI practically in everyday business scenarios and learn about the most popular AI models."
        },
        {
            title: "How to communicate with confidence about AI",
            description: "Build confidence to discuss AI in professional settings, read and comprehend AI news and literature with ease."
        },
        {
            title: "How to confidently work with AI  Engineers",
            description: "Learn how AI companies function and gain best practices for working with machine learning professionals."
        },
    ]

    return (
        <>
            <div ref={whatInsideRef} className='what__you__learn__main custom__container'>
                <div className='what__you__learn__subdiv img__section'>
                    <img src={image} alt='' />
                </div>
                <div className='what__you__learn__subdiv text__section'>
                    <h3>
                        What you’ll learn inside.
                    </h3>
                    {features?.map((feature, index) => (
                        <div key={index} className='feature__list__item'>
                            <div>
                                <CheckIcon />
                            </div>
                            <div>
                                <h4>
                                    {feature.title}
                                </h4>
                                <p>
                                    {feature.description}
                                </p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <div className='custom__container what__will__covered'>
                <h4>
                    WHAT'S COVERED
                </h4>
                <SectionHeading text={"Everything You Need to Demystify AI"} />
                <p>
                    Having spent decades building models and helping companies transform using AI, I see the vast chasm between experts and everyday understanding. This course bridges that gap, enabling you to truly grasp AI and use it to your advantage, making your life easier and better.
                </p>
            </div>
        </>
    )
}

export default Index