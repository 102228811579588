import React from 'react';
import './style.scss';
import { SectionHeading } from '../../../components';
import { ListCheckIcon, StarIcon } from '../../../assets/icons';

const Index = ({pricingRef}) => {

    const pricing = [
        {
            title: "One-time Payment",
            price: "<span>$99</span> $50",
            description: "Parturient a id id suspendisse sit libero, faucibus. Orci fusce ac mauris lectus duis nibh cras fames.",
            features: [
                "All course modules and in-depth chapters 📘",
                "Quizzes to assess your understanding 📝",
                "Lifetime access to course materials 🔒",
                "Unlimited access to a Slack channel for questions  💬",
                "Full course in audio podcast mode 🎧 ",
                "Full course in video mode 🎥",
                "Full course text to read through 📄",
                "BONUS: Assessment of your AI ideas 💡",
                "BONUS: resources to continue your AI journey 🚀",
            ],
            btnText: "Access for <span>$99</span> $50"
        }
    ]

    return (
        <div ref={pricingRef} className="pricing__main__section custom__container">
            <SectionHeading text="Ready to be a League Ahead?" />
            <p className='section__description'>
                Universities charge thousands for this knowledge, and I wanted this to be accessible for everyone. If you can't afford it, DM me on X for grant options.
            </p>
            <div className='pricing__card__section'>
                {pricing?.map((v, i) => {
                    return <div className='pricing__card' key={i}>
                        <div className='d-flex align-items-center gap-3'>
                            <div>
                                <StarIcon />
                            </div>
                            <div className='card__title__section'>
                                <p>
                                    {v?.title}
                                </p>
                            </div>
                        </div>
                        <h3 className='card__price' dangerouslySetInnerHTML={{ __html: v?.price }} />
                        <div className='empty__line' />
                        <div className='featues__section'>
                            <p className='price__description'>
                                {v?.description}
                            </p>
                            {v?.features?.map((e, i) => {
                                return <div key={i} className='d-flex gap-2 align-items-center'>
                                    <div>
                                        <ListCheckIcon />
                                    </div>
                                    <div className='feature__list__text__section'>
                                        <p>
                                            {e}
                                        </p>
                                    </div>
                                </div>
                            })}
                            <button dangerouslySetInnerHTML={{ __html: v?.btnText }} className='get__started__btn' />
                        </div>
                    </div>
                })}
            </div>
        </div>
    )
}

export default Index
