import React from 'react';
import './style.scss'

const SectionHeading = ({ text }) => {
    return (
        <h2 className='section__heading'>
            {text}
        </h2>
  )
}

export default SectionHeading
