import React from 'react';
import './style.scss';
import featureImg from '../../../assets/images/featureImg.png';

const Index = () => {

    const features = [
        {
            title: "Understand AI and ML",
            description: "In this section, we'll dive deep into what Artificial Intelligence and Machine Learning really are, ensuring you understand them fully. We'll provide plenty of examples to illustrate the concepts."
        },
        {
            title: "What’s Needed for AI to Work?",
            description: `While AI might seem like magic, you must know these four things that work together to make that magic happen.`
        },
        {
            title: "What Are Models and How Do They Learn?",
            description: "You’ve often heard about “models” but you probably don’t know what they really are and how they work. After this chapter, you’ll understand them and the model landscape."
        },
        {
            title: "Neural Nets, LLMs, and Generative AI",
            description: "AI was always around but this is why you have heard about it and care about AI today. It’s about time you learn what makes it so transformative"
        },
    ]

    return (
        <div className='features__section custom__container'>
            {features?.map((v, index) => (
                <div key={index} className={`feature__item ${index % 2 !== 0 ? "even__item" : ""}`}>
                    <div className='feature__item__img__section'>
                        <img src={featureImg} alt='' />
                    </div>
                    <div className='feature__item__text__section'>
                        <h3>{index + 1}. {v?.title}</h3>
                        <p>{v?.description}</p>
                    </div>
                </div>
            ))}
        </div>
    )
}

export default Index
