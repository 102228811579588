import React from 'react';
import './style.scss'
import { GiftIcon } from '../../../assets/icons';

const Index = ({ isSmallScreen }) => {
    return (
        <div className='custom__container'>
            {!isSmallScreen ? <div className='guaranty__section__main '>
                <div>
                    <GiftIcon />
                </div>
                <div className='guaranty__text__section'>
                    <h3>
                        The Guarantee
                    </h3>
                    <p>
                        If you don’t find incredible value, I’ll refund your money and buy you coffee for wasting your time. Plus, if within a few months of finishing the course, you can’t see how AI can save you time or make you money, I’ll give you a personal workshop session for FREE.
                    </p>
                </div>
                <div className='guaranty__btn__section'>
                    <button>
                        Access for <span>$99</span> $50
                    </button>
                </div>
            </div> :
                <>
                    <div className='guaranty__section__main'>
                        <div className='d-flex justify-content-between'>
                            <div>
                                <GiftIcon />
                            </div>
                            <div className='guaranty__btn__section'>
                                <button>
                                    Access for <span>$99</span> $50
                                </button>
                            </div>
                        </div>
                        <div className='guaranty__text__section'>
                            <h3>
                                The Guarantee
                            </h3>
                            <p>
                                If you don’t find incredible value, I’ll refund your money and buy you coffee for wasting your time. Plus, if within a few months of finishing the course, you can’t see how AI can save you time or make you money, I’ll give you a personal workshop session for FREE.
                            </p>
                        </div>
                    </div>
                </>
            }
        </div>
    )
}

export default Index
